<template>
    <div id="comparison">
        <div class="title">( {{ total }} / {{ max }} ) 对比</div>
        <div class="comparison-content">
            <div class="comparison-item" v-for="(item, index) in prods" :key="index">
                <el-image :src="item.img"></el-image>
                <div class="item-title text-hide-2">{{ item.name }}</div>
            </div>
            <div class="operate">
                <router-link tag="div" :to="{ name: 'comparison' }" class="operate-item">开始对比
                </router-link>
                <div class="operate-item" @click="emptyComparison">清空对比</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
    name: 'comparison',
    data () {
        return {

        }
    },
    computed: {
        ...mapState('comparison', ['prods', 'max']),
        total () {
            return this.$store.getters['comparison/getProds']
        },
    },
    methods: {
        ...mapMutations('comparison', ['emptyComparison']), // 数组写法 清空对比数据
    },
    mounted () {
    }
}
</script>

<style lang="less" scoped>
#comparison {
    position: fixed;
    right: 50%;
    bottom: 290px;
    margin-right: -836px;
    z-index: 1500;
    width: 120px;
    user-select: none;

    .title {
        height: 52px;
        line-height: 52px;
        color: #fff;
        text-align: center;
        background-color: #EF312A;
    }

    .comparison-content {
        border: 1px solid rgba(112, 112, 112, 0.10);
        background-color: #fff;

        .comparison-item {
            text-align: center;
            padding: 10px;
            border-bottom: 1px solid rgba(46, 48, 51, .1);

            .item-title {
                color: #2E3033;
                font-size: 14px;
            }
        }

        .operate {
            padding: 10px;

            .operate-item {
                padding: 10px;
                text-align: center;
                cursor: pointer;
                color: #2E3033;
                transition: color 0.3s, background-color 0.3s;

                &:first-child {
                    color: #fff;
                    background-color: #EF312A;

                    &:hover {
                        background-color: rgba(239, 49, 42, .8);
                    }
                }

                &:last-child {
                    color: rgba(46, 48, 51, .6);
                }
            }
        }
    }
}
</style>