<template>
    <div>
        <el-dialog title="我要发布" :visible.sync="dialogVisible" width="60%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="标题名称" prop="title">
                    <el-input v-model="ruleForm.title" placeholder="请输入标题名称"></el-input>
                </el-form-item>
                <el-form-item label="内 容" prop="content">
                    <vue-ueditor-wrap v-model="ruleForm.content" editor-id="editor" :config="editorConfig"
                        :editorDependencies="['ueditor.config.js', 'ueditor.all.js']" />
                </el-form-item>
                <el-form-item label="封面图片" prop="pic">
                    <div class="el-upload__tip mt-0">建议尺寸：550px*528px</div>
                    <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
                        :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm()">取 消</el-button>
                <el-button type="danger" @click="submitForm()">发 布</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
    name: "teachingCenterDialog",
    components: {
        VueUeditorWrap
    },
    data () {
        return {
            dialogVisible: false,
            ruleForm: {
                title: '',
                content: '',
                pic: ''
            },
            rules: {
                title: [
                    { required: true, message: '请输入标题名称', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请输入发布内容', trigger: 'blur' }
                ],

            },
            editorConfig: {
                // 后端服务地址，后端处理参考
                // https://open-doc.modstart.com/ueditor-plus/backend.html
                serverUrl: '/api/path/to/server',
                UEDITOR_HOME_URL: '/static/UEditorPlus/',
                UEDITOR_CORS_URL: '/static/UEditorPlus/',
            }
        }
    },
    mounted () {

    },
    methods: {
        handleRemove () { },
        handlePictureCardPreview () { },
        submitForm () {
            console.log(this.ruleForm)
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    console.log('submit!')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm () {
            this.$refs['ruleForm'].resetFields()
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;

    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);

    .el-descriptions__title {
        text-indent: 30px;
    }

    .el-descriptions__extra {
        margin-right: 15px;
    }
}

/deep/.el-upload-list {
    &__item {
        width: 79px;
        height: 79px;

        &:last-child {
            margin-right: 5px;
        }
    }
}

/deep/.el-upload--picture-card {
    width: 79px;
    height: 79px;
    line-height: 81px;
}
</style>