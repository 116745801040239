<template>
    <div class="cart-item">
        <h3>供应商：{{ item.supplier }}</h3>
        <div class="cart-item-content">
            <div class="flex">
                <el-image :src="item.img" size="100px" lazy></el-image>

                <div class="ml-15">
                    <h2>{{ item.name }}</h2>
                    <div class="font-14">
                        <p class="text-gray mb-15">类型：{{ item.typeName }}</p>
                        <p class="text-gray">单价：<span class="text-red">￥{{ item.price }}</span></p>
                    </div>
                </div>
            </div>


            <div>
                <el-input-number v-model="item.num" :min="1" size="mini" :max="item.stock"></el-input-number>
            </div>
            <div class="text-right">
                小计：<span class="text-red font-18">￥{{ totalPrice }}</span>
            </div>
            <div class="text-center">
                <el-link type="danger" :underline="false" @click="removeCart(item.id)">删除</el-link>
            </div>

        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    name: "cartItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
        }
    },
    computed: {
        totalPrice () {
            return (this.item.num * this.item.price).toFixed(2)
        }
    },
    methods: {
        ...mapMutations('carts', ['removeCart']),
    }
}
</script>

<style lang="less" scoped>
.cart-item {
    padding: 15px;
    background: #F9FAFC;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: .3s;

    &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    }

    .cart-item-content {
        display: grid;
        grid-template-columns: 1fr 10% 20% 100px;
    }

    h3 {
        color: #2E3033;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .el-image {
        border-radius: 10px;
    }

    h2 {
        color: #2E3033;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
    }
}
</style>