<template>
    <el-row :gutter="15">
        <el-col :span="9">
            <div class="carousel-container">
                <hooper group="group1" ref="carousel" class="carousel-main">
                    <slide v-for="(item, index) in carousel" :key="index">
                        <template v-if="item.video">
                            <video :src="item.video" width="100%" height="100%" controls autoplay preload="auto"
                                oncontextmenu="return false" controlslist="nodownload"></video>

                        </template>
                        <template v-else>
                            <img :src="item.image" draggable="false">
                        </template>
                    </slide>
                </hooper>

                <hooper group="group1" :itemsToShow="4" :centerMode="false" class="carousel-thumbnails">
                    <slide v-for="(item, index) in carousel" :key="index">
                        <img :src="item.image" draggable="false" @click="handleCarouselSlide(index)">
                    </slide>

                    <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
                </hooper>
            </div>
        </el-col>
        <el-col :span="15">
            <div class="grid-content bg-purple"></div>
        </el-col>
    </el-row>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
    name: "supplierBlock",
    components: {
        Hooper,
        Slide,
        HooperNavigation
    },
    data () {
        return {
            carousel: [
                {
                    title: '商品名称1',
                    video: 'https://cms.sdcms.cn/upfile/2020/08/1597194275517.mp4',
                    image: 'https://picsum.photos/320/320?1'
                },
                {
                    title: '商品名称2',
                    image: 'https://picsum.photos/320/320?2'
                },
                {
                    title: '商品名称3',
                    image: 'https://picsum.photos/320/320?3'
                },
                {
                    title: '商品名称4',
                    image: 'https://picsum.photos/320/320?4'
                },
                {
                    title: '商品名称5',
                    image: 'https://picsum.photos/320/320?5'
                },
                {
                    title: '商品名称6',
                    image: 'https://picsum.photos/320/320?6'
                },
                {
                    title: '商品名称7',
                    image: 'https://picsum.photos/320/320?7'
                }
            ]
        }
    },
    mounted () {
        // 获取video元素
        const video = document.querySelector('video')
        // 页面可见性变化时暂停或播放视频
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                video.pause()
            }
        })

        // 当视频不在可视范围内时暂停
        const observer = new IntersectionObserver((entries) => {
            if (!entries[0].isIntersecting) {
                video.pause()
            }
        }, { threshold: 0 })

        observer.observe(video)
    },
    methods: {
        handleCarouselSlide (index) {
            this.$refs.carousel.slideTo(index)
        }
    }
}
</script>

<style lang="less" scoped>
.carousel-container {
    width: 320px;

    /deep/.hooper-navigation {
        pointer-events: none;

        .hooper-prev,
        .hooper-next {
            height: 36px;
            width: 36px;
            text-align: center;
            padding: 0;
            transition: .3s;
            opacity: 0;
            border-radius: 50%;
            background-color: rgba(31, 45, 61, .11);
        }

        .hooper-prev {
            left: -60px;
        }

        .hooper-next {
            right: -60px
        }
    }


    .carousel-main {
        height: 320px;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid rgba(46, 48, 51, 0.1);

        .hooper-list {
            .hooper-slide {
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    user-select: none;
                }
            }
        }
    }

    .hooper {
        &:hover {
            /deep/.hooper-navigation {
                .hooper-prev {
                    opacity: 1;
                    left: 0;
                }

                .hooper-next {
                    opacity: 1;
                    right: 0;
                }
            }
        }
    }

    .carousel-thumbnails {
        height: 72px;
        margin-top: 10px;

        .hooper-navigation {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateY(-50%);
        }

        .hooper-slide {
            width: 72px !important;
            height: 72px;
            margin-right: 10px;
            border-radius: 10px;
            border: 1px solid rgba(46, 48, 51, 0.1);
            overflow: hidden;
            cursor: pointer;

            &:hover,
            &.is-current {
                border-color: #EF312A;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                user-select: none;
            }
        }
    }
}
</style>