<template>
    <div>
        <el-descriptions title="装备参数规格" :column="1" border>
            <el-descriptions-item label="产品尺寸">135mm*30mm*23mm</el-descriptions-item>
            <el-descriptions-item label="整机重量">
                <div style="width:990px">100 g</div>
            </el-descriptions-item>
            <el-descriptions-item label="接收频率">BDS B1l和 GPS L1</el-descriptions-item>
            <el-descriptions-item label="通道数目">>32 通道</el-descriptions-item>
            <el-descriptions-item label="定位精度">水平≤10m</el-descriptions-item>
            <el-descriptions-item label="首次定位时间">冷启动≤40s，热启动(需备用电源)5s</el-descriptions-item>
            <el-descriptions-item label="捕获灵敏度">133dBm</el-descriptions-item>
            <el-descriptions-item label="电池容量">1500mAh/6.0V</el-descriptions-item>
            <el-descriptions-item label="电池年限">3年</el-descriptions-item>
            <el-descriptions-item label="安装方式">背夹/挂绳安装</el-descriptions-item>
            <el-descriptions-item label="工作温度">20~55℃</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    name: "baseInfo",
    components: {

    },
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;
    text-indent: 30px;
    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);
}
</style>