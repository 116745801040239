<template>
    <div>
        <headers />
        <div class="container mt-30 mb-30">
            <el-card>
                <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/biaoxun' }">热门标讯</el-breadcrumb-item>
                    <el-breadcrumb-item>热门标讯详情</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="article">
                    <h1>江西今年要建成市县级应急物资库61个</h1>
                    <div class="info">
                        <span>2024.04.26 17:28:44</span> | <span>采购人:淮南市消防救援支队</span> | <span>代理机构:安徽东晟工程咨询有限公司</span>
                    </div>
                    <div class="content">
                        <p>“去年政府和园区使用专项资金近200万元，补充完善一批常用的环境应急物资，目前这些应急物资有共20个种类，在一些骨干企业均备有应急物资，还会结合实际情况适时补充更新。”日前，江西景德镇陶瓷工业园区化工集中区仓库保管员指着仓库里的应急物资向在场的生态环境工作人员介绍道。
                        </p>

                        <p>近日，江西省生态环境厅成立工作组，下沉一线督导帮扶辖区化工园区进一步加强应急物资储备管理，建立健全环境应急物资保障机制。</p>

                        <p>“我们储备的物资种类要齐全，数量要足，确保一旦发生突发环境事件，在关键时刻能发挥作用。”江西省生态环境应急调查中心科长何坤现场查看物资仓库时强调到。</p>

                        <p>江西近年来不断加大常用物资储备力度，在不断完善全省第一批26个化工园区环境应急物资储备的基础上，全力推动第二批20个化工园区配备符合相关国家标准、行业标准要求的应急物资及装备，持续提升化工园区应急准备能力。
                        </p>

                        <p>据了解，2019年江西建立了全国统一的环境应急物资信息库，省内应急物资生产及储备信息及时收入环境应急物资库系统，并且每半年组织一次环境应急物资信息的核实、补录和更新，全面掌握各地应急物资实物和产能信息。
                        </p>

                        <p>截至目前，江西全省新增18家重点物资储备单位，扩充应急物资信息库达478个，物资信息8014条，较去年新增物资信息库114个、新增物资信息3184条。</p>

                        <p><img src="../../assets/img/3ea6beec64369c2642b92.png" alt=""></p>

                        <p>江西还在全省推广应急物资属地政府实物储备、生产企业产能储备、重点企业协议储备相结合的“九江模式”，针对化工园区、化学品运输码头、水上交通事故高发地段以及油气管道等重点区域和特征污染物，科学确定环境应急物资种类及数量，依托环境应急物资生产企业实现产能储备，依托重点企业实现协议储备。并优化储备布点与调配使用，着力构建多层级物资储备调用体系，全省11个设区市共签订应急物资储备调用合作协议50份。
                        </p>

                        <p>据介绍，2024年底前，江西省、市、县配备一批满足突发环境事件应急指挥、应急监测、应急交通、个人防护需要的应急物资装备。</p>

                        <p>“我们今年计划将购置省级环境应急物资装备50余套，并推动各地围绕当地产业特征和实际需求，按照填平补齐原则，以市级30万、县级15万的资金补助标准，对现有应急装备和应急物资进行补充配备，充实应急装备及应急物资储备，建成市级应急物资库11个、县级应急物资库50个，构建快速响应的1小时应急物资调用圈”。江西省生态环境应急调查中心主任周少华介绍说。
                        </p>
                    </div>
                </div>
            </el-card>
        </div>
        <footers />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"
export default {
    name: "biaoxunDetails",
    components: {
        headers,
        footers
    },
    data () {
        return {
            id: this.$route.params.id,
        }
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.article {
    h1 {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        text-align: center;
    }

    .info {
        line-height: 50px;
        border-bottom: 1px solid #f3f3f3;
        margin-bottom: 20px;
        color: #3A9B18;
        text-align: center;

        span {

            i {
                margin-right: 5px;
            }
        }
    }

    .content {
        a {
            color: #03C;

            &:hover {
                color: #f30;
            }
        }

        p {
            margin-bottom: 20px;
            line-height: 30px;
        }

        img {
            max-width: 100%;
        }
    }

}
</style>