<template>
    <div>
        <el-dialog title="我要讨论" :visible.sync="dialogVisible" width="60%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-form-item prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" :autosize="{ minRows: 6 }" minlength="10"
                        maxlength="200" show-word-limit placeholder="请输入讨论内容"></el-input>
                </el-form-item>
                <el-form-item prop="pics">
                    <div class="el-upload__tip mt-0"></div>
                    <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :limit="9"
                        :multiple="true" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :on-exceed="limitExceed">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="danger" @click="submitForm('ruleForm')">发 布</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="previewDialogVisible">
            <img width="100%" :src="dialogImageUrl">
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "bbsDialog",
    data () {
        return {
            dialogVisible: false,
            dialogImageUrl: '',
            previewDialogVisible: false,
            ruleForm: {
                content: '',
                pics: []
            },
            rules: {
                content: [
                    { required: true, message: '请输入讨论内容', trigger: 'blur' },
                    { min: 10, max: 20, message: '长度在 10 到 200 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    mounted () {

    },
    methods: {
        handleRemove (file, fileList) {
            console.log(file, fileList)
        },
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url
            this.previewDialogVisible = true
        },
        limitExceed (files, fileList) {
            this.$message.error(`最多只能上传 9 张图片`)
            return false
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
            this.dialogVisible = false
            this.previewDialogVisible = false
            this.dialogImageUrl = ''
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;

    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);

    .el-descriptions__title {
        text-indent: 30px;
    }

    .el-descriptions__extra {
        margin-right: 15px;
    }
}



/deep/.el-upload-list {
    &__item {
        width: 79px;
        height: 79px;

        &:last-child {
            margin-right: 5px;
        }
    }
}

/deep/.el-upload--picture-card {
    width: 79px;
    height: 79px;
    line-height: 81px;
}
</style>