<template>
    <div>
        <h1>北斗信号弹TD10（北斗三号短报文应急示位搜救终端）</h1>
        <div class=""></div>
        <el-descriptions :column="1">
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-time mr-sm"></i>
                    发布时间
                </template>2024-05-21 09:20:15
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="iconfont icon-danwei mr-sm"></i>
                    所属单位
                </template>xxx公司
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="iconfont icon-dianhua mr-sm"></i>
                    联系方式
                </template>张经理 18574589632
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="iconfont icon-liulan mr-sm"></i>
                    浏览总数
                </template>4151
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions>
            <el-descriptions-item label="参考报价" :labelStyle="{
                'display': 'flex', 'align-items': 'center'
            }">
                <span class="font-24 text-red">￥100000</span>

                <span class="ml-30">历年中标价</span>
                <span class="font-24 text-red">￥1000 - ￥2000</span>
            </el-descriptions-item>
        </el-descriptions>
        <div class="mt-30" style="display: grid;grid-template-columns: repeat(3, 1fr);">
            <el-button type="danger">立即购买</el-button>
            <el-button type="danger" plain>采购咨询</el-button>
            <el-button type="danger" plain>加入对比</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "info",
    components: {

    },
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
h1 {
    color: #2E3033;
    font-size: 20px;
    margin-bottom: 30px;
}
</style>