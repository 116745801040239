<template>
    <div>
        <div class="el-descriptions">
            <div class="el-descriptions__header">
                <div class="el-descriptions__title">
                    <el-radio-group v-model="type">
                        <el-radio :label="1">只看教学视频</el-radio>
                        <el-radio :label="2">只看教学资料</el-radio>
                    </el-radio-group>
                </div>
                <div class="el-descriptions__extra">
                    <el-button type="danger" icon="el-icon-edit"
                        @click="$refs.teachingCenterDialog.dialogVisible = true">我要发布</el-button>
                </div>
            </div>
        </div>
        <div class="mt-30">
            <div class="list">
                <item v-for="(item, index) in list" :key="index" :item="item" />
            </div>
        </div>

        <teachingCenterDialog ref="teachingCenterDialog" />
    </div>
</template>

<script>
import item from "./item.vue"
import teachingCenterDialog from './teachingCenterDialog.vue'


export default {
    name: "display",
    components: {
        item,
        teachingCenterDialog
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            type: 1,
        }
    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;

    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);

    .el-descriptions__title {
        text-indent: 30px;

        .el-radio-group {
            display: flex;

            .el-radio {
                margin-right: 0;

                .el-radio__input {
                    display: inline;
                }

                &.is-checked {
                    .el-radio__inner {
                        border-color: #EF312A;
                        background: #EF312A;
                    }

                    .el-radio__label {
                        color: #606266;
                    }
                }
            }
        }
    }

    .el-descriptions__extra {
        margin-right: 15px;
    }
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    margin-top: 30px;
}
</style>