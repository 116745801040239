<template>
    <div class="address-item" :class="{ selected: item.default }">
        <div>
            <div class="to">
                <el-link type="danger" :underline="false">
                    <i class="iconfont icon-ditubiaozhu"></i>
                    <strong class="ml-10">寄送至</strong>
                </el-link>
            </div>
        </div>
        <div>
            <span>
                {{ item.province }}{{ item.city }}{{ item.area }}{{ item.address }}（{{ item.name
                }}收）
            </span>
            <span class="text-gray">{{ item.tel }}</span>
        </div>
        <div>
            <span class="text-gray">默认地址</span>
            <el-popconfirm confirm-button-text='确定' cancel-button-text='算了' icon="el-icon-warning-outline"
                title="确定设为默认地址吗？" @confirm="setDefaultAddress(item)">
                <el-link slot="reference" type="danger" :underline="false">设为默认地址</el-link>
            </el-popconfirm>
        </div>
        <div class="text-right">
            <el-link type="danger" :underline="false" @click="addEditFun('edit', item)"><strong>修改地址</strong></el-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "biaoxunItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
        }
    },
    computed: {
    },
    methods: {
        setDefaultAddress (item) {
            this.$emit('setDefaultAddress', item)
        },
        addEditFun (type, item) {
            this.$emit('addEditFun', type, item)
        }
    }
}
</script>

<style lang="less" scoped>
.address-item {
    display: grid;
    grid-template-columns: 10% 1fr auto 10%;
    grid-column-gap: 20px;
    font-size: 16px;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;

    .el-link {
        font-size: 16px;
    }

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &:hover {
        background-color: rgba(223, 41, 34, 0.10);
    }

    &.selected {
        border-color: #EF312A;
        background-color: rgba(223, 41, 34, 0.10);
    }

}
</style>