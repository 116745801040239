<template>
    <div>
        <div class="el-descriptions">
            <div class="el-descriptions__header">
                <div class="el-descriptions__title">装备评价（{{ total }}条）</div>
                <div class="el-descriptions__extra">
                    <el-button type="danger" icon="el-icon-edit"
                        @click="$refs.evaluateDialog.dialogVisible = true">我要评价</el-button>
                </div>
            </div>
        </div>
        <div class="mt-30">
            <div class="evaluate-item" v-for="item in list" :key="item.id">
                <el-row :gutter="30">
                    <el-col :span="2">
                        <el-avatar :size="60" :src="item.avatar" lazy></el-avatar>
                    </el-col>
                    <el-col :span="22">
                        <div class="evaluate-item__title flex">
                            <span class="username">普通用户</span>
                            <el-rate v-model="item.score" disabled show-score text-color="#ff9900" :max="10">
                            </el-rate>
                        </div>
                        <div class="evaluate-item__content">
                            <p><strong>优点：</strong>{{ item.youdian }}</p>
                            <p><strong>缺点：</strong>{{ item.quedian }}</p>
                        </div>
                        <div class="evaluate-item__pics">
                            <el-image :size="120" v-for="(item, index) in item.pics" :key="index" :src="item.pic"
                                lazy></el-image>
                        </div>
                        <div class="evaluate-item__footer flex space-between">
                            <div>
                                <span>购买时间：{{ item.createTime }}</span>
                                <span>购买地址：{{ item.addr }}</span>
                            </div>
                            <div class="operate">
                                <span><i class="el-icon-chat-dot-square"></i>评论</span>
                                <span><i class="el-icon-thumb"></i>点赞</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <evaluateDialog ref="evaluateDialog" />
    </div>
</template>

<script>
import evaluateDialog from './evaluateDialog.vue'
export default {
    name: "display",
    components: {
        evaluateDialog
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            total: 2514145,
            totalCount: 12345,
            currentPage: 1,
        }
    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;

    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);

    .el-descriptions__title {
        text-indent: 30px;
    }

    .el-descriptions__extra {
        margin-right: 15px;
    }
}

.evaluate-item {
    padding-top: 30px;
    overflow: hidden;

    &__title {
        .username {
            color: #2E3033;
            font-size: 16px;
            margin-right: 10px;
        }
    }

    &__content {
        margin-top: 15px;
        font-size: 14px;
        color: #2E3033;

        p {
            line-height: 1.6;
            margin-bottom: 5px;
        }
    }

    &__pics {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 10px;

        .el-image {
            width: 60px;
            height: 60px;
            border-radius: 5px;
        }
    }

    &__footer {
        font-size: 14px;
        color: rgba(46, 48, 51, .6);

        span {
            &+span {
                margin-left: 30px;
            }

            i {
                margin-right: 5px;
            }
        }

        .operate {
            span {
                cursor: pointer;

                &:hover {
                    color: rgba(46, 48, 51, 1);
                }
            }
        }
    }

    .el-col-22 {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(46, 48, 51, .1)
    }
}
</style>