<template>
    <div>
        <div class="text-center mb-20">
            ———— 该商品历史成交总金额：{{ total }}元，成交总数量：{{ totalCount }}件 ————
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="title" label="采购单位"></el-table-column>
            <el-table-column prop="num" label="采购数量" width="120" align="center"></el-table-column>
            <el-table-column prop="price" label="采购价格" width="120" align="center">
                <template slot-scope="scope">
                    <span class="text-yellow">￥{{ scope.row.price }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="supplier" label="供应商"></el-table-column>
            <el-table-column prop="tradedate" label="成交日期" width="120"></el-table-column>
        </el-table>
        <div class="text-center mt-30">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                layout="total, sizes, prev, pager, next, jumper" :total="400">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "display",
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            total: 2514145,
            totalCount: 12345,
            currentPage: 1,
        }
    },
    mounted () {

    },
    methods: {
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`)
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`)
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-table {
    th {
        background-color: rgba(46, 48, 51, .05);
    }
}
</style>