<template>
    <div>
        <el-descriptions title="装备基本信息" :column="1" border>
            <el-descriptions-item label="装备名称">北斗信号弹TD10(北斗三号短报文应急示位搜救终端)</el-descriptions-item>
            <el-descriptions-item label="摘要">
                <div style="width:990px">北斗信号弹TD10(北斗三号短报文便携式樓救终端)是一款人员落水报警器，具有北斗三号单向短报文通信、 北斗/GPS
                    定位、落水自动报警、-键SOS主动报曾等功能，是专门为应急救援场景而研制的机型。</div>
            </el-descriptions-item>
            <el-descriptions-item label="产地">广东广州</el-descriptions-item>
            <el-descriptions-item label="咨询联系人">王慧芳 18988921983</el-descriptions-item>
            <el-descriptions-item label="分类">应急处置装备</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    name: "baseInfo",
    components: {

    },
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;
    text-indent: 30px;
    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);
}
</style>