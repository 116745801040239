<template>
    <div>
        <el-descriptions title="装备展示" :column="1" border>
            <template slot="extra">
                <div class="tabs">
                    <div class="item" v-for="item in type" :key="item.val" :class="{ active: activeTab === item.val }"
                        @click="activeTab = item.val">{{ item.name }}</div>
                </div>
            </template>
        </el-descriptions>
        <div class="content">
            <template v-if="activeTab === 0">
                <el-image src="https://picsum.photos/1190/600" lazy>
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                </el-image>
            </template>
            <template v-if="activeTab === 1">
                <video src="https://cms.sdcms.cn/upfile/2020/08/1597194275517.mp4" width="100%" height="100%" controls
                    autoplay preload="auto" oncontextmenu="return false" controlslist="nodownload"></video>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "display",
    components: {

    },
    data () {
        return {
            activeTab: 0,
            type: [
                { val: 0, name: '图片' },
                { val: 1, name: '视频' }
            ]
        }
    },
    mounted () {
        // 获取video元素
        const video = document.querySelector('video')
        // 页面可见性变化时暂停或播放视频
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                video.pause()
            }
        })

        // 当视频不在可视范围内时暂停
        const observer = new IntersectionObserver((entries) => {
            if (!entries[0].isIntersecting) {
                video.pause()
            }
        }, { threshold: 0 })

        observer.observe(video)
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;

    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);

    .el-descriptions__title {
        text-indent: 30px;
    }

    .el-descriptions__extra {
        margin-right: 30px;

        .tabs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 36px;
            line-height: 36px;
            border-radius: 17px;
            background-color: #fff;
            overflow: hidden;

            .item {
                width: 70px;
                text-align: center;
                border-radius: 17px;
                cursor: pointer;

                &.active {
                    color: #fff;
                    background-color: #EF312A;

                    &:hover {
                        color: #fff;
                    }
                }

                &:hover {
                    color: #EF312A;
                }
            }
        }
    }
}
</style>