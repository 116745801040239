<template>
    <div>
        <router-link :to="{ name: 'videoDetails', params: { id: item.id } }" class="video-item">
            <div class="video-item-img">
                <el-image :src="item.pic" lazy></el-image>
                <div class="video-item-btn">
                    <i class="iconfont icon-bofang"></i>
                    {{ item.duration }}
                </div>
            </div>
            <div class="video-item-info">
                <div class="video-item-title">
                    <h3 class="text-hide">{{ item.title }}</h3>
                    <span>{{ formattedNumber }}播放</span>
                </div>
                <p class="video-item-time">发布时间：{{ item.createTime }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
import { prettyFormatNumber } from "@/utils/index"
export default {
    name: "biaoxunItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
        }
    },
    computed: {
        formattedNumber () {
            return prettyFormatNumber(this.item.hits)
        },
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.video-item {
    display: block;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid rgba(46, 48, 51, 0.1);
    text-decoration: none;

    &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

        .video-item-img {
            .video-item-btn {
                i {
                    color: #ef312a;
                }
            }
        }

        .video-item-info {
            .video-item-title {
                h3 {
                    color: #ef312a;
                }
            }
        }
    }

    .video-item-img {
        position: relative;
        width: 253px;
        height: 253px;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;

        .el-image {
            width: 100%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .video-item-btn {
            position: absolute;
            right: 0;
            bottom: 10px;
            height: 30px;
            line-height: 30px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 15px 0px 0px 15px;
            color: #FFFFFF;
            font-size: 14px;
            padding: 0 10px;

            i {
                transition: color 0.3s ease;
            }
        }
    }

    .video-item-info {
        padding: 15px;

        .video-item-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                color: #2E3033;
                font-size: 16px;
                font-weight: 500;
                max-width: 137px;
                transition: color 0.3s ease;
            }

            span {
                color: rgba(46, 48, 51, .6);
                font-size: 12px;
            }
        }

        .video-item-time {
            margin-top: 10px;
            color: rgba(46, 48, 51, .6);
            font-size: 12px;
        }
    }


}
</style>