<template>
    <el-card>
        <h3>同类型产品推荐</h3>
        <div class="recProList">
            <div class="item" v-for="item in list" :key="item.id">
                <el-image :src="item.pic" lazy></el-image>
                <div class="title">
                    <h4 class="text-hide">{{ item.title }}</h4>
                </div>
                <el-button type="danger" size="mini" plain>加入对比</el-button>
            </div>
        </div>
    </el-card>
</template>

<script>
export default {
    name: "btns",
    props: {
        list: {
            type: Array,
            default: () => { }
        }
    },
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.recProList {
    .item {
        padding: 10px;
        text-align: center;

        &:not(:last-child) {
            border-bottom: 1px solid rgba(46, 48, 51, .1)
        }

        /deep/.el-image {
            width: 140px;
            height: 140px;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }
        }

        .title {
            margin: 10px 0;

            h4 {
                margin: 0;
                color: #2E3033;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }
}
</style>