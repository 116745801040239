<template>
    <div>
        <headers />

        <div class="container">
            <el-row :gutter="30">
                <el-col :span="16">
                    <el-card>
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item :to="{ name: 'products' }">全部装备</el-breadcrumb-item>
                            <el-breadcrumb-item>装备详情</el-breadcrumb-item>
                        </el-breadcrumb>
                        <el-row :gutter="15">
                            <el-col :span="9">
                                <carousel />
                            </el-col>
                            <el-col :span="15">
                                <info />
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <supplierBlock />
                </el-col>
            </el-row>

            <el-row :gutter="30" class="mt-30 mb-30">
                <el-col :span="20">
                    <el-card>
                        <div class="mb-30">
                            <el-button v-for="btn in btns" :key="btn.id" :type="btn.id === activeBtnId ? 'danger' : ''"
                                @click="activeBtnId = btn.id">{{ btn.name }}</el-button>
                        </div>
                        <template v-if="activeBtnId === 0">
                            <baseInfo />
                        </template>
                        <template v-if="activeBtnId === 1">
                            <parameter />
                        </template>
                        <template v-if="activeBtnId === 2">
                            <display />
                        </template>
                        <template v-if="activeBtnId === 3">
                            <sales :list="salesList" />
                        </template>
                        <template v-if="activeBtnId === 4">
                            <evaluate :list="evaluateList" />
                        </template>
                        <template v-if="activeBtnId === 5">
                            <teachingCenter :list="teachingVideoList" />
                        </template>
                        <template v-if="activeBtnId === 6">
                            <bbs :list="evaluateList" />
                        </template>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <recProd :list="recProList" />
                </el-col>
            </el-row>
        </div>
        <footers />
        <comparison />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"
import comparison from "@/components/comparison.vue"

import supplierBlock from "./components/supplierBlock.vue"
import carousel from "./components/carousel.vue"
import info from "./components/info.vue"
import recProd from "./components/recProd.vue"
import baseInfo from "./components/baseInfo.vue"
import parameter from "./components/parameter.vue"
import display from "./components/display.vue"
import sales from "./components/sales.vue"
import evaluate from "./components/evaluate.vue"
import teachingCenter from "./components/teachingCenter.vue"
import bbs from "./components/bbs.vue"


import { getRecProductType, getSales, getEvaluate, getTeaching } from "@/api/products"

export default {
    name: "Procucts",
    components: {
        headers,
        footers,
        comparison,
        supplierBlock,
        carousel,
        info,
        recProd,
        baseInfo,
        parameter,
        display,
        sales,
        evaluate,
        teachingCenter,
        bbs
    },
    data () {
        return {
            activeBtnId: 0,
            btns: [
                { id: 0, name: '基本信息' },
                { id: 1, name: '参数规格' },
                { id: 2, name: '装备展示' },
                { id: 3, name: '销售记录' },
                { id: 4, name: '装备评价' },
                { id: 5, name: '教学中心' },
                { id: 6, name: '产品论坛' }
            ],



            recProList: [],
            salesList: [],
            evaluateList: [],
            teachingVideoList: [],
        }
    },
    created () {

    },
    mounted () {
        this.searchRPT()
        this.searchSales()
        this.searchEvaluate()
        this.searchTeachingVideo()
    },
    methods: {
        async searchRPT () {
            const res = await getRecProductType()
            this.recProList = res.data.data.list
        },
        async searchSales () {
            const res = await getSales()
            this.salesList = res.data.data.list
        },
        async searchEvaluate () {
            const res = await getEvaluate()
            this.evaluateList = res.data.data.list
        },
        async searchTeachingVideo () {
            const res = await getTeaching()
            this.teachingVideoList = res.data.data.list
        },
    }
}
</script>

<style lang="less" scoped>
.hd-title {
    text-align: center;
    margin-top: 60px;

    h3 {
        font-size: 28px;
        font-weight: 700;
        color: #2E3033;
    }

    p {
        font-size: 14px;
        color: rgba(46, 48, 51, .6);
        margin-top: 10px;
    }
}

/deep/.el-input__suffix {
    right: 0;
}

.orderBy {
    .el-button {
        border-color: transparent;

        &.el-button--danger:hover {
            color: #fff;
        }

        &:hover {
            color: #000;
            background-color: transparent;
        }
    }
}

.video-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    margin-top: 30px;
}
</style>