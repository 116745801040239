<template>
    <div>
        <el-dialog title="我要评价" :visible.sync="dialogVisible" width="60%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="我的评分" prop="score">
                    <el-rate v-model="ruleForm.score" :allow-half="true" show-score text-color="#ff9900"
                        style="margin-top: 10px;"></el-rate>
                </el-form-item>
                <el-form-item label="装备优点" prop="advantage">
                    <el-input v-model="ruleForm.advantage" type="textarea" :autosize="{ minRows: 4 }" minlength="10"
                        maxlength="200" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="装备缺点" prop="defect">
                    <el-input v-model="ruleForm.defect" type="textarea" :autosize="{ minRows: 4 }" minlength="10"
                        maxlength="200" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="图片/视频" prop="file">
                    <div class="el-upload__tip mt-0">你还可以添加9张照片，还可以添加1个视频</div>
                    <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
                        :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="购入时间" prop="buyTime">
                            <el-date-picker v-model="ruleForm.buyTime" :picker-options="pickerOptions" type="date"
                                placeholder="选择购入时间"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="购入地址" prop="addr">
                            <el-select v-model="ruleForm.adrr" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="danger" @click="submitForm('ruleForm')">发 布</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="previewDialogVisible">
            <img width="100%" :src="dialogImageUrl">
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "evaluateDialog",
    data () {
        return {
            dialogVisible: false,
            dialogImageUrl: '',
            previewDialogVisible: false,
            pickerOptions: {
                disabledDate (time) {
                    return time.getTime() > Date.now()
                },
                shortcuts: [{
                    text: '今天',
                    onClick (picker) {
                        picker.$emit('pick', new Date())
                    }
                }, {
                    text: '昨天',
                    onClick (picker) {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        picker.$emit('pick', date)
                    }
                }, {
                    text: '一周前',
                    onClick (picker) {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                        picker.$emit('pick', date)
                    }
                }]
            },
            options: [{
                value: '北京',
                label: '北京'
            }, {
                value: '安徽',
                label: '安徽'
            }, {
                value: '上海',
                label: '上海'
            }],
            ruleForm: {
                scpre: 0,
                advantage: '',
                buyTime: '',
                addr: '',
                defect: '',
                file: []
            },
            rules: {
                scpre: [
                    { required: true, message: '请选择评分', trigger: 'change' },
                ],
                advantage: [
                    { required: true, message: '请输入装备优点', trigger: 'blur' },
                    { min: 10, max: 20, message: '长度在 10 到 200 个字符', trigger: 'blur' }
                ],
                defect: [
                    { required: true, message: '请输入装备缺点', trigger: 'blur' },
                    { min: 10, max: 20, message: '长度在 10 到 200 个字符', trigger: 'blur' }
                ],

            }
        }
    },
    mounted () {

    },
    methods: {
        handleRemove (file, fileList) {
            console.log(file, fileList)
        },
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url
            this.previewDialogVisible = true
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-descriptions__header {
    height: 62px;

    margin-bottom: 0;
    background-color: rgba(46, 48, 51, .05);

    .el-descriptions__title {
        text-indent: 30px;
    }

    .el-descriptions__extra {
        margin-right: 15px;
    }
}


/deep/.el-upload-list {
    &__item {
        width: 79px;
        height: 79px;

        &:last-child {
            margin-right: 5px;
        }
    }
}

/deep/.el-upload--picture-card {
    width: 79px;
    height: 79px;
    line-height: 81px;
}
</style>