<template>
    <div>
        <headers />
        <div class="container mb-30">

            <el-card>
                <h3>填写并核对订单信息</h3>
                <el-divider></el-divider>
                <div class="flex align-items space-between">
                    <h4>收货人信息</h4>
                    <el-link type="danger" :underline="false" @click="addEditFun('add', {})">
                        <strong>新增收货地址</strong>
                    </el-link>
                </div>

                <div class="text-center pt-30 pb-10" v-if="addrList.length === 0">
                    <el-button @click="addEditFun('add', {})">新增收货地址</el-button>
                </div>
                <div class="mt-15">
                    <addr-item v-for="(item, index) in visibleAddresses" :key="index" :item="item"
                        @setDefaultAddress="setDefaultAddress" @addEditFun="addEditFun" />
                </div>
                <div class="mt-20" v-if="!showAll && addrList.length < maxAddressesToShow">
                    <el-link :underline="false" @click="showMore">显示更多地址<i class="el-icon-arrow-down"></i></el-link>
                </div>


                <el-divider></el-divider>
                <h4>支付方式</h4>
                <div class="pt-30 pb-10">
                    <el-button :type="payType === 'wechat' ? 'danger' : 'default'"
                        @click="payType = 'wechat'">微信支付</el-button>
                    <el-button :type="payType === 'alipay' ? 'danger' : 'default'"
                        @click="payType = 'alipay'">支付宝支付</el-button>
                </div>
                <el-divider></el-divider>
                <strong class="text-red">测试加入购物车</strong>
                <ul>
                    <li v-for="item in prods" :key="item.id" @click="addCart(item)">ID{{ item.id }} - 商品名称：{{ item.name
                        }}</li>
                </ul>
                <el-divider></el-divider>

                <h4>购物车</h4>
                <div class="mt-30">
                    <cartItem v-for="item in carts" :key="item.id" :item="item" />
                </div>


                <el-card>
                    <div class="flex align-items space-between text-gray">
                        <p class="mb-sm">共 <span class="text-red">{{ totalNum }}</span> 件商品，合计：<span
                                class="text-red">￥{{ totalPrice }}</span></p>
                        <el-button type="danger" size="large">立即支付</el-button>
                    </div>
                </el-card>
            </el-card>
        </div>
        <footers />
        <addrDialog ref="addrDialog" />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"
import addrItem from "./components/addrItem.vue"
import addrDialog from "./components/addrDialog.vue"
import cartItem from "./components/cartItem.vue"

import { mapState, mapMutations } from "vuex"

import { getAddr } from "@/api/cart"

export default {
    name: "Procucts",
    components: {
        headers,
        footers,
        addrItem,
        addrDialog,
        cartItem
    },
    data () {
        return {
            num: 1,
            payType: 'wechat',
            addrList: [],
            maxAddressesToShow: 5, // 默认显示的地址数量
            showAll: false, // 是否显示全部地址

            //prods: this.$store.state.prods,
            //carts: this.$store.state.carts,
        }
    },
    computed: {
        ...mapState('carts', ['prods', 'carts']),// 数组写法
        /* ...mapState('carts', { // 对象写法
            totalPrice: this.$store.getters['carts/getTotalNum'],
            totalNum: 'getTotalNum'
        }), */
        totalPrice () {
            return this.$store.getters['carts/getTotalPrice']
        },
        totalNum () {
            return this.$store.getters['carts/getTotalNum']
        },

        // 计算当前应该显示的地址列表
        visibleAddresses () {
            // 如果显示全部地址，则返回所有地址
            if (this.showAll) {
                return this.addrList
            }
            // 否则返回最多 maxAddressesToShow 条地址
            return this.addrList.slice(0, this.maxAddressesToShow)
        },
    },
    created () {

    },
    mounted () {
        this.searchAddr()
    },
    methods: {
        ...mapMutations('carts', ['addCart']), // 数组写法 添加商品至购物车
        async searchAddr () {
            const res = await getAddr()
            //this.addrList = res.data.data.list
            res.data.data.list.forEach(item => {
                item.default = false
            })
            this.addrList = res.data.data.list
            this.addrList[0].default = true
        },
        showMore () {
            // 当点击显示更多时，设置 showAll 为 true 以显示所有地址
            this.showAll = true
        },
        setDefaultAddress (item) {
            // 设置默认地址
            this.addrList.forEach(addr => {
                addr.default = false
            })
            item.default = true
        },
        addEditFun (type, item) {
            this.$refs.addrDialog.showDialog(type, item)
        },
        /* addCart (item) {
            // 调用 store 中的 addCart 方法，将商品添加到购物车
            this.$store.commit('addCart', item)
        }, */
    }
}
</script>

<style lang="less" scoped></style>